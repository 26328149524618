import React, {useState, useEffect} from 'react';
import { 
  Table,
  Button,
  ButtonGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
  Badge,
  Form,
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWrench, faTrashAlt, faCheck } from '@fortawesome/free-solid-svg-icons'
import Loader from '../../components/Loader';
import confirm from "reactstrap-confirm";
import api from '../../services/client/api';
import cookie from '../../services/cookie/index';
import { useHistory } from 'react-router-dom';
import {deleteCookies} from '../../utils/functions';

function Users({openUpdateUser}) {

  let [page, setPage] = useState(1)
  let [qtdPages, setQtdPages] = useState(0)
  let [search, setSearch] = useState()
  let [inProgress, setInProgress] = useState(true);
  let [users, setUsers] = useState([])
  let [usersFound, setUsersFound] = useState([])
  let history = useHistory();
  const qtdPerPage = 15;

  useEffect(() => {
    if (users.length <= 0) {
      getAllUsers(page)
    }
  }, [])
  
  useEffect(() => {
    console.log(usersFound)
  }, [usersFound])
  
  useEffect(() => {

    if (search !== "") {
      let usersSearching = users.filter((user) => user.name.includes(search) || user.username.includes(search) || user.email.includes(search))
      setUsersFound(usersSearching)
      setQtdPages(Math.round(usersSearching.length/15))
      setPage(1)
      return
    }
    
    setPage(1)
    setQtdPages(Math.round(users.length/15))
    setUsersFound(users)
  }, [search])

  const getAllUsers = async () => {
    await setInProgress(true)

    const token = await cookie().get("token")
    const response = await api().user().getAllByPage(token);
    
    if (response.status == 401) 
    {
      await deleteCookies();
      return history.push({ pathname:"/login"});
    } 
    
    if (response.status == 200) 
    {
      setUsers(response.output)
      setUsersFound(response.output)
      setQtdPages(Math.round(response.output.length/15))
      setInProgress(false)
      
      return
    } 
    setInProgress(false)
    setUsers([])
    setUsersFound([])

  }

  const updateBanned = async (id, banned, name) => {
    let result = await confirm({
        title: (banned ? 'Ativar' : 'Excluir') + " Usuário",
        message: (<>Você tem certeza que deseja {banned ? 'ativar' : 'excluir'} <b>{name}</b> ?</>),
        cancelText: "Cancelar",
        cancelColor: "secondary",
        confirmText: (banned ? 'Ativar' : 'Excluir'),
        confirmColor: (banned ? 'success' : 'danger'),
    });

    if (result) {
      await setInProgress(true)

      const token = await cookie().get("token")

      let response;
      if (banned) {
        response = await api().user().active(token, id);
      } else {
        response = await api().user().ban(token, id);
      }
      
      if (response.status == 200) 
      {
        return history.go(0)
      } 
      setInProgress(false)
    }
  }

  const PaginationComponent = () => {
    let element = [];

    for (let index = page-3; index < page; index++) {
      let thisPage = index
      if (thisPage > 0) {
        element.push(
          <PaginationItem active={thisPage===page} >
            <PaginationLink onClick={() => setPage(thisPage)}>
            {thisPage}
            </PaginationLink>
          </PaginationItem>
        )

      }
    }

    for (let index = page-1; index < qtdPages && index < page+3; index++) {
      let thisPage = index+1
      element.push(
        <PaginationItem active={thisPage===page} >
          <PaginationLink onClick={() => setPage(thisPage)}>
          {thisPage}
          </PaginationLink>
        </PaginationItem>
      )
    }

    return (
      <Pagination aria-label="">

        <PaginationItem>
          <PaginationLink first onClick={() => setPage(1)} disabled={page==1} />
        </PaginationItem>

        <PaginationItem disabled={page==1}>
          <PaginationLink previous onClick={() => setPage(page-1)} />
        </PaginationItem>

        {element.map((e)=>e)}

        <PaginationItem disabled={page==qtdPages}>
          <PaginationLink next onClick={() => setPage(page+1)}/>
        </PaginationItem>
        
        <PaginationItem disabled={page==qtdPages}>
          <PaginationLink last onClick={() => setPage(qtdPages)} />
        </PaginationItem>
      </Pagination>
    )
  }
  
  return (
    
    <div>

      <Form>
        <Row>
          <Col md={6}>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    Buscar Usuário
                  </InputGroupText>
                </InputGroupAddon>

                <Input 
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  required 
                  type="text"
                />

              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </Form>

      {(inProgress) && (<Loader/>)}
      
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Permissão</th>
            <th>Login</th>
            <th>Ativo</th>
            <th>Funções</th>
          </tr>
        </thead>
        <tbody>
          {usersFound.slice(qtdPerPage*(page-1), qtdPerPage*page).map((user) => (
            <tr>
              <th scope="row">{user.id}</th>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>{user.username}</td>
              <td>
                {!user.banned ?
                  <Badge color="success" pill>Ativo</Badge>
                  :
                  <Badge color="danger" pill>Inativo</Badge>
                }
              </td>
              <td>
                <ButtonGroup>
                  <Button 
                    color="primary"
                    onClick={() => openUpdateUser(user.id)}
                  >
                    <FontAwesomeIcon icon={faWrench} />
                  </Button>
                  <Button 
                    color={user.banned ? 'success' : 'danger'}
                    title={user.banned ? 'Ativar usuário' : 'Banir usuário'}
                    onClick={() => updateBanned(user.id, user.banned, user.name)}
                  >
                    <FontAwesomeIcon icon={user.banned ? faCheck : faTrashAlt} />
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))}
          
        </tbody>
      </Table>

      <PaginationComponent />
    </div>
  );
}

export default Users;
