import ApiConnection from './connection';

const download = async (token, numberReport, typeReport) => ApiConnection.get(`reports/download/${numberReport}/${typeReport}`, { headers: { Authorization: `Bearer ${token}` }})
.then(response => {
  return {output:response.data, status: response.status};
}).catch((e) => {
  return {status: e.response.status}
});

const getSizeList = async (token, dateStart, dateEnd, patient) => ApiConnection.get(`reports/size/${dateStart}/${dateEnd}/${patient}`, { headers: { Authorization: `Bearer ${token}` }})
.then(response => {
  return {output:response.data, status: response.status};
}).catch((e) => {
  return {status: e.response.status}
});

const getSizeListByGroup = async (token, dateStart, dateEnd, patient, groupID) => ApiConnection.get(`reports/size/${dateStart}/${dateEnd}/${groupID}/${patient}`, { headers: { Authorization: `Bearer ${token}` }})
.then(response => {
  return {output:response.data, status: response.status};
}).catch((e) => {
  return {status: e.response.status}
});

const get = async (token, sizeList, page, dateStart, dateEnd, patient) => ApiConnection.get(`reports/${sizeList}/${page}/${dateStart}/${dateEnd}/${patient}`, { headers: { Authorization: `Bearer ${token}` }})
.then(response => {
  return {output:response.data, status: response.status};
}).catch((e) => {
  return {status: e.response.status}
});

const getByGroup = async (token, sizeList, page, dateStart, dateEnd, patient, groupID) => ApiConnection.get(`reports/${sizeList}/${page}/${dateStart}/${dateEnd}/${groupID}/${patient}`, { headers: { Authorization: `Bearer ${token}` }})
.then(response => {
  return {output:response.data, status: response.status};
}).catch((e) => {
  return {status: e.response.status}
});

export default function report() {
  return {
    get,
    getByGroup,
    getSizeList,
    getSizeListByGroup,
    download
  }
}