import React, {useEffect, useState} from 'react';
import { 
  Alert,
  Spinner,
  FormGroup,
  Form,
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Row,
  Col,
  Label,
  Badge,
} from 'reactstrap';
import { FormWrapperForm, InputView, WrapperCodeInstitucional } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faEnvelope,
  faUserCheck,
  faCheck,
  faKey,
  faTimes,
  faWrench
} from '@fortawesome/free-solid-svg-icons'
import api from '../../services/client/api';
import { useHistory } from 'react-router-dom';
import cookie from '../../services/cookie/index';
import {deleteCookies} from '../../utils/functions';

export default function UpdateUser({userID}) {

  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState(false);
  const [first, setFirst] = useState(true);
  const [fields, setFields] = useState({role: 'admin', banned: false, code: ""});
  const [msgError, setMsgError] = useState('erro');
  const history = useHistory();
  const [codeInstitucionalList, setCodeInstitucionalList] = useState([]);
  const [codeInstitucional, setCodeInstitucional] = useState('');
  
  useEffect(() => first&&getUser() )

  const removeCodeInstitucional = (codeRemove) => {
    codeInstitucionalList = codeInstitucionalList.filter(code => code != codeRemove);
    setCodeInstitucionalList([ ...codeInstitucionalList])
  }

  const addCodeInstitucional = () => {
    setCodeInstitucionalList([ ...codeInstitucionalList, codeInstitucional])
    setCodeInstitucional('')
  }

  const getArrayCodes = (outputAPI) => {
    let arrayCodes = []
    for (const userInstitution of outputAPI) {
      arrayCodes.push(userInstitution.code_instituicao)
    }
    return arrayCodes
  }

  const getUser = async () => {
    
    setFirst(false)

    const token = await cookie().get("token")
    const response = await api().user().getByID(token, userID);
    
    if (response.status == 401) 
    {
      await deleteCookies();
      return history.push({ pathname:"/login"});
    } 
        
    if (response.status == 200) 
    {
      setFields(response.output);

      const responseUserInstitution = await api().userInstitution().getByUser(token, userID);
      if (responseUserInstitution.status == 200) {
        setCodeInstitucionalList(getArrayCodes(responseUserInstitution.output))
      }

      return 
    } 

  }

  const create = async (e) => {
    e.preventDefault();
    setInProgress(true);

    fields.code_institution = codeInstitucionalList

    const token = await cookie().get("token")
    const response = await api().user().update(token, fields, userID);
    
    if (response.status == 401) 
    {
      await deleteCookies();
      return history.push({ pathname:"/login"});
    } 
    
    if (response.status == 201) 
    {
      return history.go(0);
    } 

    setMsgError(response.message)
    setError(true)
    setInProgress(false);

  }

  return (
    <FormWrapperForm>
      
      {(error)&& <Alert color="danger">{msgError}</Alert>}

      <Form onSubmit={create}>

        <Row form>

          <Col md={6}>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faUser} />
                  </InputGroupText>
                </InputGroupAddon>

                <InputView required value={fields.name} placeholder="Nome" name="name" onChange={(e) => setFields({...fields, name:e.target.value})}/>

              </InputGroup>
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </InputGroupText>
                </InputGroupAddon>

                <InputView required value={fields.email} placeholder="E-mail" type="email" name="email" onChange={(e) => setFields({...fields, email:e.target.value})}/>

              </InputGroup>
            </FormGroup>
          </Col>

        </Row>
        <Row form>

          <Col md={6}>
            <FormGroup>

              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faUser} />
                  </InputGroupText>
                </InputGroupAddon>
              
                <InputView required value={fields.username} placeholder="Login" name="user_name" onChange={(e) => setFields({...fields, username:e.target.value})}/>

              </InputGroup>
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>

              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faKey} />
                  </InputGroupText>
                </InputGroupAddon>

                <InputView required value={fields.password} placeholder="Senha" type="password" name="password" onChange={(e) => setFields({...fields, password:e.target.value})}/>
              
              </InputGroup>
            </FormGroup>
          </Col>

        </Row>

        <Row form>

          <Col md={6}>
            <FormGroup>
              
              <Label>Permissão</Label>

              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faUserCheck} /> 
                  </InputGroupText>
                </InputGroupAddon>

                <InputView value={fields.role} placeholder="Permissão" type="select" name="role" onChange={(e) => setFields({...fields, role:e.target.value})}>
                  <option value="admin">admin</option>
                  <option value="super_medico">super_medico</option>
                  <option value="medico">medico</option>
                  <option value="institucional">institucional</option>
                  <option value="auditor">auditor</option>
                </InputView>

              </InputGroup>
            </FormGroup>
          </Col>

          { fields.role != "admin" &&

            <Col md={6}>
              <FormGroup>

                <Label>Código</Label>

                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FontAwesomeIcon icon={faKey} />
                    </InputGroupText>
                  </InputGroupAddon>

                  <InputView placeholder="Código" value={fields.code} name="code" onChange={(e) => setFields({...fields, code:e.target.value})}/>
                
                </InputGroup>
              </FormGroup>
            </Col>
          }
          
          { fields.role == "auditor" &&

            <Col md={12}>
              <FormGroup>
                <Label>Códigos Instituições</Label>
                <InputGroup>
                  <InputView placeholder="Código Institucional" value={codeInstitucional} onChange={(e) => setCodeInstitucional(e.target.value)} />
                  <InputGroupAddon addonType="append">
                    <Button color="primary" onClick={addCodeInstitucional}>Adicionar</Button>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              
              <WrapperCodeInstitucional>
                {codeInstitucionalList.map((code) => (
                  <Badge color="secondary" pill>
                    {code} <FontAwesomeIcon onClick={() => removeCodeInstitucional(code)} icon={faTimes} />
                  </Badge>
                ))}
                {codeInstitucionalList.length <= 0 && 'Nenhum Código adicionado'}
              </WrapperCodeInstitucional>
            </Col>
          }

          <Col md={6}>
            <FormGroup>

              <Label>Ativo</Label>

              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faCheck} />
                  </InputGroupText>
                </InputGroupAddon>

                <InputView placeholder="Ativo" value={fields.banned} type="select" name="banned" onChange={(e) => setFields({...fields, banned:e.target.value})}>
                  <option value={false}>Ativo</option>
                  <option value={true}>Inativo</option>
                </InputView>

              </InputGroup>
            </FormGroup>
          </Col>

        </Row>
        
        
          { (inProgress) ?
              <Spinner color="primary" />
            :
            <Button color="primary" type="submit">
              <FontAwesomeIcon icon={faWrench} /> Editar
            </Button>
          }
      </Form>

    </FormWrapperForm>
  );
}